import React, { Component } from 'react'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import queryString from 'query-string'
import _ from 'lodash'
import { replaceAllSearchParams } from '../../utils/utils'

const GoogleMapStyles = styled.div`
  .map-container {
    height: 300px;
    width: 300px;
  }

  .hidden {
    display: none;
  }

  @media all and (min-width: 700px) {
    .map-container {
      height: 500px;
      width: 500px;
    }
  }

  @media all and (min-width: 1136px) {
    .map-container {
      height: 600px;
      width: 600px;
    }
  }
`

const center = {
  lat: 43.932536,
  lng: -84.555534,
}

const mapOptions = {
  // clickableIcons: false,
  fullScreenControl: false,
  disableDefaultUI: true,
  zoomControl: true,

  styles: [],
}

const navigateHandler = (title, location) => {
  const searchParamsString = replaceAllSearchParams(location, {
    location: encodeURIComponent(title),
  })

  navigate(`/find-a-chorus?${searchParamsString}`, {
    replace: false,
  })
}

class GoogleMapComponent extends Component {
  getSearchParam() {
    const { gatsLoc } = this.props
    const { search } = gatsLoc

    return {
      cat: _.get(queryString.parse(search), ['cat'], null),
      selectedLoc: _.get(queryString.parse(search), ['location'], null),
    }
  }

  updateLocations() {
    const { locations } = this.props
    const { cat } = this.getSearchParam()
    const filteredChoruses = locations.filter(loc => {
      if (cat) {
        return loc.category === cat
      }
      return true
    })

    return filteredChoruses
  }

  render() {
    const locations = this.updateLocations()
    const { gatsLoc } = this.props
    const { selectedLoc } = this.getSearchParam()

    return (
      <LoadScript
        googleMapsApiKey={process.env.GATSBY_GOOGLE_MAPS_API_KEY}
        mapId={process.env.GATSBY_GOOGLE_MAPS_ID}
      >
        <GoogleMap
          mapId={process.env.GATSBY_GOOGLE_MAPS_ID}
          mapContainerClassName="map-container"
          // mapContainerStyle={mapProps}
          center={center}
          zoom={6}
          options={mapOptions}
        >
          {locations.map(loc => {
            const isSelected = loc.title === selectedLoc

            const selectIcon =
              'http://maps.google.com/mapfiles/ms/icons/red-dot.png'
            const defaultIcon =
              'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
            return (
              <Marker
                key={loc.id}
                text={loc.title}
                styles={{
                  backgroundColor: 'blue',
                }}
                icon={isSelected ? selectIcon : defaultIcon}
                position={loc.location}
                title={loc.title}
                onClick={() => {
                  navigateHandler(loc.title, gatsLoc)
                }}
              />
            )
          })}
        </GoogleMap>
      </LoadScript>
    )
  }
}

export default function GenerateGoogleMap({ locations, loc }) {
  return (
    <GoogleMapStyles>
      <GoogleMapComponent
        className="google-map"
        locations={locations}
        gatsLoc={loc}
      />
    </GoogleMapStyles>
  )
}
