import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import GoogleMaps from '../components/googleMaps/googleMaps'
import { filterChoruses } from '../utils/utils'
import ProcessRichText from '../utils/processRichText'
import SimpleFilter from '../components/simpleFilter'

const FindChorusStyles = styled.div`
  .content {
    min-width: 100%;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;

    .map {
      align-self: center;
      margin-bottom: 20px;
      /* margin: 0 auto; */
    }
  }

  @media all and (min-width: 1136px) {
    .content {
      justify-content: space-around;
    }
  }
`

const ChorusListStyles = styled.div`
  margin: 0 auto;
`

const SingleChorusStyles = styled.div`
  .container {
    margin: 0 auto;
    /* min-width: 300px; */
    width: 100%;

    .image-container {
      width: 70%;
      margin: 15px auto;

      img {
        margin: 0 auto;
      }
    }

    .chorus-contact {
      text-align: center;
      .site {
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 2.5rem;
      }
    }
  }

  @media all and (min-width: 700px) {
    .container {
      min-width: 400px;
      width: 100%;

      .image-container {
        /* width: 100%; */
      }
    }
  }
`

const SingleChorus = ({ chorus }) => {
  let addressInfo
  if (chorus.content) {
    addressInfo = <ProcessRichText curProp={chorus} />
  }

  let imageAsset
  if (chorus.image) {
    imageAsset = (
      <div className="image-container">
        <Img fluid={chorus.image.asset.fluid} alt={chorus.image.alt} />
      </div>
    )
  }

  return (
    <SingleChorusStyles>
      <div className="container item-wrapper">
        <h2 className="title">{chorus.title}</h2>
        <h4>{chorus.chapter}</h4>
        {imageAsset}
        <div>{addressInfo}</div>
        <div className="chorus-contact">
          <h4 className="site">
            {chorus.website ? (
              <a
                className="dark"
                href={chorus.website}
                rel="noopener noreferrer"
                target="_blank"
              >
                Visit Website
              </a>
            ) : (
              <></>
            )}
          </h4>
          <h4>
            <a className="dark number" href={`tel:${chorus.number}`}>
              {chorus.number}
            </a>
          </h4>
        </div>
      </div>
    </SingleChorusStyles>
  )
}

const getCats = choruses =>
  choruses.reduce((obj, c) => {
    if (!obj[c.category]) {
      obj[c.category] = 0
    }

    obj[c.category] += 1
    return obj
  }, {})

export default function FindAChorus({ data, location }) {
  const choruses = data.choruses.nodes
  const filteredChoruses = filterChoruses(choruses, location)
  const cats = getCats(choruses)

  return (
    <FindChorusStyles className="content-width">
      <h1 className="title">Find a Chorus</h1>
      <p>
        Using the map below, select a pin to view details about choruses in your
        area. Use the filters to view different types of choruses.
      </p>
      <SimpleFilter cats={cats} location={location} includeCount />
      <div className="content">
        <div className="map">
          <GoogleMaps locations={choruses} loc={location} />
        </div>
        <ChorusListStyles>
          {filteredChoruses.map(chorus => (
            <SingleChorus key={chorus.id} chorus={chorus} />
          ))}
        </ChorusListStyles>
      </div>
    </FindChorusStyles>
  )
}

export const query = graphql`
  query ChorusesQuery {
    choruses: allSanityChoruses {
      nodes {
        category
        chapter
        email
        id
        number
        title
        website
        content: _rawAddressInfo
        image {
          alt
          asset {
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
        location {
          lat
          lng
        }
      }
    }
  }
`
